/**
 * Styles specific to the footer app, scoped to the "app-footer" class to
 * ensure that they do not affect any other apps.
 */

.app-footer {
  @import "~@insight/toolkit-css-core/src/_all";
  @import "~@insight/toolkit-css-ui";

  background-color: $color-black-light;

  /**
   * Tweaks to Accordion specifically for the dark themed Footer
   *
   * 1. Hack to remove double border, due to accordions being placed directly 
   *    below one another rather than spaced out.
   */
  .c-footer-accordion {
    margin-bottom: -1px; /* [1] */
  }

  .c-footer-accordion__content {
    padding: $global-spacing-unit;
  }

  .c-footer-accordion__controls {
    padding-left: $global-spacing-unit-small + $global-spacing-unit-tiny;
  }

  .c-footer-accordion__item {
    border-left: 0;
    border-right: 0;
  }

  .c-footer-gradient {
    background-image: $global-gradient-primary-v2;
    padding: $global-spacing-unit;
  }

  .c-footer-gradient__list-item {
    margin-bottom: 0px;
    @include mq($from: desktop) {
      text-align: center;
    }
  }

  .c-footer-gradient__list-item, .c-footer__text {
    color: $color-white;
  }

  .c-footer__heading {
    color: $color-white;
    @include font-family-body($font-size: $global-font-size, $margin: 0 0 $global-spacing-unit-small);
  }

  .c-footer__inline-list {
    flex-wrap: wrap;
    margin-bottom: $global-spacing-unit-small;

    @include mq($from: desktop) {
      margin-bottom: 0;
      justify-content: flex-end;
    }
  }

  .c-footer__inline-list-item {
    margin-right: $global-spacing-unit;
    margin-bottom: $global-spacing-unit-small;

    @include mq($from: desktop) {
      margin-right: 0;
      margin-bottom: $global-spacing-unit-tiny;
      margin-left: $global-spacing-unit;
    }
  }

  .c-footer__inline-list-item:last-child {
    margin-right: 0;
  }

  .c-footer__list {
    margin-bottom: 0;
  }

  .c-footer__list-button {
    margin-top: 15px;
  }

  .c-footer__list-item {
    margin-bottom: $global-spacing-unit-tiny;
  }

  .c-footer__list-item:last-child {
    margin-bottom: 0;
  }

  .c-footer__list-link {
    font-size: $global-font-size;
  }

  .c-footer--magazine {
    margin-top: 50px;
  }

  .c-footer--magazine__mobile {
    margin-top: 30px;
  }

  .c-footer__mobile-list-item {
    padding-bottom: $global-spacing-unit-tiny;
  }

  .c-footer__mobile-list-item:last-child {
    padding-bottom: 0;
  }

  .c-footer-padding {
    padding: $global-spacing-unit;
  }

  .c-footer-padding-large {
    padding: $global-spacing-unit;

    @include mq($from: desktop) {
      padding: $global-spacing-unit-large;
    }
  }

  .c-footer-social {
    border-bottom: 1px solid $color-warm-gray-8;
    border-top: 1px solid $color-warm-gray-8;
  }

  .c-footer-social__link {
    display: block;
  }

  .c-footer-social__list {
    margin-bottom: 0;
    justify-content: center;
  }

  .c-footer--techjournal {
    width: 70px;
    height: 36px;
    color: $color-white;
    margin-left: -7px !important;
    margin-top: 5px;
  }

  .c-footer__text {
    font-size: $global-font-size-small;
  }

  /**
   * FIXME: Remove once solution found in AEM itself as this is a temp CSS fix and overrides Design System component styling.
   * We can't add this fix to `toollkit-css-ui` as it breaks the Design System. The fix below only works for AEM.
   *
   * AEM will rewrite URL’s to the relative path, e.g.
   * Instead of `url(#xing-gradient)` AEM will rewrite the URL to `url(app-footer#xing-gradient)` which breaks the styling.
   *
   * To overcome this, we need to tell AEM to not process this url, we can do so by adding the string "absolute:" to the beginning of the url.
   * See article below for more info:
   * https://blogs.perficient.com/2017/08/10/how-to-force-aem-to-not-process-css-urls/
   */
  .c-social-link-xing:hover .c-social-icon--xing > * {
    fill: url(absolute:#xing-gradient);
  }
  .c-social-icon--brand-color-on-hover > * {
    fill: $color-red-fuchsia;
  }
}



